var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"oia-correctives"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',[_c('h1',{staticClass:"title"},[_vm._v("Correctifs OIA")])]),_c('datatable',{attrs:{"fetch":_vm.$Api.ReferenceBudgetPrestation.fetchCapFnp,"entity":"referenceBudgetPrestation","downloadEntity":"reference-budget-prestations/oia-correctives","enableDownload":""},scopedSlots:_vm._u([{key:"project",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Projet","sortable":true,"sortBy":"referenceBudget.sheet.project.name"}},[_vm._v(_vm._s(item.projectName))])}},{key:"sheet",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Fiche Affaire","sortable":true,"sortBy":"referenceBudget.sheet.name"}},[_vm._v(_vm._s(item.sheetName))])}},{key:"category",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Catégorie","sortable":true,"sortBy":"prestation.category.parent.name"}},[_vm._v(_vm._s(item.categoryName))])}},{key:"subcategory",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Sous-catégorie","sortable":true,"sortBy":"prestation.category.name"}},[_vm._v(_vm._s(item.subCategoryName))])}},{key:"prestation",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Prestation","sortable":true,"sortBy":"prestation.name"}},[_vm._v(_vm._s(item.prestationName))])}},{key:"capFnp",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":"Montant CAP/FNP","sortable":true}},[_vm._v(_vm._s(_vm._f("priceEUR")(item.capFnp)))])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }