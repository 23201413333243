<template>
  <div class="oia-correctives">
    <div class="container is-fluid">
      <page-header>
        <h1 class="title">Correctifs OIA</h1>
      </page-header>

      <datatable :fetch="$Api.ReferenceBudgetPrestation.fetchCapFnp" entity="referenceBudgetPrestation" downloadEntity="reference-budget-prestations/oia-correctives" enableDownload>
        <div slot="project" slot-scope="{ item }" title="Projet" :sortable="true" sortBy="referenceBudget.sheet.project.name">{{ item.projectName }}</div>
        <div slot="sheet" slot-scope="{ item }" title="Fiche Affaire" :sortable="true" sortBy="referenceBudget.sheet.name">{{ item.sheetName }}</div>
        <div slot="category" slot-scope="{ item }" title="Catégorie" :sortable="true" sortBy="prestation.category.parent.name">{{ item.categoryName }}</div>
        <div slot="subcategory" slot-scope="{ item }" title="Sous-catégorie" class="" :sortable="true" sortBy="prestation.category.name">{{ item.subCategoryName }}</div>
        <div slot="prestation" slot-scope="{ item }" title="Prestation" class="" :sortable="true" sortBy="prestation.name">{{ item.prestationName }}</div>
        <div slot="capFnp" slot-scope="{ item }" title="Montant CAP/FNP" class="has-text-right" :sortable="true">{{ item.capFnp | priceEUR }}</div>
      </datatable>
    </div>
  </div>
</template>

<script>
import Datatable from '../../components/elements/Datatable';

export default {
  name: 'oia-correctives',
  components: {
    Datatable,
  },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>

<style scoped>
</style>
